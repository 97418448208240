<template>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Título</span>
            </v-card-title>
            <v-card-text class="pb-0" v-if="titulo">
                <div >
                    <v-row>
                        <v-col sm="3" cols="12" >
                            <span style="white-space: nowrap" class="d-block caption font-weight-medium">Serviço</span>
                            <v-btn x-small class="primary" v-if="titulo.projeto"
                                   :to="{ name: 'projeto_view', params: { id: parseInt(titulo.projeto.id) }}">
                                Abrir Projeto
                            </v-btn>
                        </v-col>
                        <v-col sm="3" cols="6">
                            <span class="d-block caption font-weight-medium">Nosso Número</span>
                            <span>{{titulo.nossoNumero}}</span>
                        </v-col>
                        <v-col sm="3" cols="6">
                            <span class="d-block caption font-weight-medium">Número</span>
                            <span>{{titulo.numero}}</span>
                        </v-col>
                        <v-col sm="3" cols="12" class="pb-0">
                            <span class="d-block caption font-weight-medium">Emissão</span>
                            <span style="white-space: nowrap">{{titulo.emissao | moment('DD/MM/YYYY HH:mm')}}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col  cols="12" sm="auto" class="shrink ">
                            <span class="d-block caption font-weight-medium">Pagador</span>
                            <span >{{titulo.nomePagador}}</span>
                        </v-col>
                        <v-col  cols="12" sm="auto" class="grow">
                            <span class="d-block caption font-weight-medium">Inscrição</span>
                            <span>{{formatCpfCnpj(titulo.inscricaoPagador)}}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="shrink">
                            <span class="d-block caption font-weight-medium">Agência</span>
                            <span>{{titulo.agencia}}-{{titulo.agenciaDigito}}</span>
                        </v-col>
                        <v-col cols="6" sm="auto" class="grow">
                            <span class="d-block caption font-weight-medium">Conta</span>
                            <span>{{titulo.conta}}-{{titulo.contaDigito}}</span>
                        </v-col>
                        <v-col cols="6" sm="auto" class="grow">
                            <span class="d-block caption font-weight-medium">Valor</span>
                            <span>{{titulo.valor | formatNumber}}</span>
                        </v-col>
                        <v-col cols="6" sm="3" class="shrink">
                            <span style="white-space: nowrap" class="d-block caption font-weight-medium">Data Vencimento</span>
                            <span>{{titulo.vencimento | moment('DD/MM/YYYY')}}</span>
                        </v-col>
                    </v-row>
                </div>
                <span class="body-1 black--text mr-2">Transações</span>
                <v-list dense >
                    <template v-for="(transacao, index) in titulo.transacoes">
                        <v-list-item :key="index"  :to="{ name: 'retorno_view', params: { id: parseInt(transacao.retorno.id) }}">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-light">
                                    Retorno Nº: {{transacao.retorno.numeroAvisoBancario}}
                                </v-list-item-title>
                                <v-list-item-subtitle class="font-weight-light">
                                    <span class="font-weight-regular">{{transacao.dataOcorrencia | moment('DD/MM/YYYY')}}</span> {{transacao.identificacaoOcorrenciaTexto}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>
                                    Valor Pago
                                </v-list-item-action-text>
                                <v-list-item-action-text>
                                    <b>{{transacao.valorPago | formatNumber}}</b>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <div class="float-right">
                        <v-btn text color="primary" @click="closeDialog">
                            Ok
                        </v-btn>
                    </div>
                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "TituloDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            titulo: null,
        }
    },
    methods: {
        openDialog(id) {
            this.dialog = true;
            this.getTitulo(id)
        },
        closeDialog() {
            this.dialog = false;
        },
        getTitulo(id){
            this.loading = true;
            this.$apollo.query({
                query: gql`query($id: Int!) {
                        titulo(id: $id){
                            id
                            nomePagador
                            inscricaoPagador
                            agencia
                            agenciaDigito
                            conta
                            contaDigito
                            valor
                            emissao
                            vencimento
                            nossoNumero
                            numero
                            projeto{
                                id
                            }
                            transacoes{
                                id
                                valorPago
                                dataOcorrencia
                                identificacaoOcorrenciaTexto
                                retorno{
                                    id
                                    numeroAvisoBancario
                                }
                            }
                        }
                    }`,
                variables: {id},
            }).then((result) => {
                this.titulo = result.data.titulo;
            }).finally(() => {
                this.loading = false;
            })
        },
        formatCpfCnpj(cpfCnpj){
            let formatCpf = (cpf) => {
                return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
            }
            let formatCnpj = (cnpj) => {
                return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5")
            }
            if(cpfCnpj.length === 11){
                return formatCpf(cpfCnpj)
            }else{
                return formatCnpj(cpfCnpj)
            }
        },
        corOcorrencia(ocorrencia){
            switch (ocorrencia){
                case 'Entrada Confirmada':
                    return 'green--text'
                case 'Entrada Rejeitada':
                    return 'red--text'
                default:
                    return ''
            }
        }
    }
}
</script>

<style scoped>

</style>