<template>
    <page v-if="remessa">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'financeiro'}">Financeiro</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'conta'}">Conta</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'remessas'}">Remessas</v-breadcrumbs-item>
        </v-breadcrumbs>
        <v-alert color="yellow" dense class="py-0" v-if="!remessa.dataEnvioArquivo">
            <v-row align="center">
                <v-col class="shrink">
                    <v-icon>
                        mdi-alert
                    </v-icon>
                </v-col>
                <v-col class="grow">
                    <span>A remessa ainda não foi transmitida.</span>
                </v-col>
                <v-col class="shrink text-right d-flex">
                    <v-btn elevation="1" small :disabled="transmitindo"
                           @click="transmitirRemessa(remessa.id)">Transmitir</v-btn>
                </v-col>
            </v-row>
        </v-alert>

        <div>
            <span class="title font-weight-regular d-inline-flex" style="line-height: normal; align-items: center">
                Nº: {{remessa.numero}}
                <v-btn :loading="downloadingRemessa" :disabled="downloadingRemessa"
                       class="ml-2" small outlined icon color="indigo" @click="downloadRemessa">
                    <v-icon small>mdi-file-download-outline</v-icon>
                </v-btn>
            </span>
            <div style="opacity: 0.6; float: right" class="d-inline-flex align-center">
                <div class="d-flex align-center">
                    <div>
                        <p class="mb-0 grey--text " style="font-size:10px; line-height: 12px">Data Emissão</p>
                        <p class="mb-0 caption grey--text text--darken-2" style="line-height: 12px">{{remessa.createdAt | moment("DD/MM/YYYY HH:mm")}}</p>
                    </div>
                </div>
            </div>
        </div>
        <span class="caption text-uppercase">
            {{remessa.descricao}}
        </span>

        <div class="d-flex mt-6  mb-6">
            <v-alert class="text-right mb-0 mr-4 py-0" text color="teal" >
                <span class="overline">Valor Títulos</span>
                <span class="headline d-block">
                    {{remessa.valorTotal | formatNumber}}
                </span>
            </v-alert>
        </div>

        <v-card outlined class="mb-6" v-if="remessa.titulos">
            <v-card-title class="pb-0">
                Títulos
                <span v-if="remessa.titulos.length > 0" class="ml-2 subtitle-2 font-weight-regular">{{remessa.titulos.length}} itens</span>
            </v-card-title>
            <v-card-text class="pa-0">
                <!--<v-list class="py-0" :three-line="$vuetify.breakpoint.smAndUp" :two-line="$vuetify.breakpoint.xsOnly" v-if="agencia.fiscalizacoes && agencia.fiscalizacoes.length > 0">-->
                <v-list class="mt-2 py-0" two-line>
                    <template v-for="(titulo, index) in remessa.titulos">
                        <v-list-item two-line @click="showTitulo(titulo.id)">
                            <v-list-item-content>
                                <v-list-item-title>{{titulo.nomePagador}}</v-list-item-title>
                                <v-list-item-subtitle>AG: {{titulo.agencia}}-{{titulo.agenciaDigito}} C/C: {{titulo.conta}}-{{titulo.contaDigito}}</v-list-item-subtitle>
                                <v-list-item-subtitle></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>
                                    <b>{{titulo.valor | formatNumber}}</b>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider ></v-divider>
                    </template>
                </v-list>
            </v-card-text>
        </v-card>
        <titulo-dialog ref="tituloDialog" />
    </page>
</template>

<script>
import gql from "graphql-tag";
import page from "@/components/Page";
import TituloDialog from "@/views/financeiro/conta/remessa/TituloDialog";

export default {
    name: "View",
    components:{
        TituloDialog,
        page,
    },
    data() {
        return {
            remessa: null,
            loading: false,
            transmitindo: false,
            downloadingRemessa: false,
        }
    },
    mounted() {
        this.getRemessa()
    },
    methods: {
        getRemessa() {
            const id = this.$route.params.id;
            this.$apollo.query({
                query: gql`query($id: Int!) {
                        remessa(id: $id){
                            id
                            numero
                            valorTotal
                            descricao
                            createdAt
                            dataEnvioArquivo
                            titulos{
                                id
                                inscricaoPagador
                                nomePagador
                                numero
                                nossoNumero
                                agencia
                                agenciaDigito
                                conta
                                contaDigito
                                emissao
                                valor
                                vencimento
                                projeto{
                                    id
                                }
                            }
                        }
                    }`,
                variables: {id},
            }).then((result) => {
                this.remessa = result.data.remessa;
                document.title = "Nº: " + this.remessa.numero + " - JF Assessoria Rural"
            });
        },
        showTitulo(id){
            this.$refs.tituloDialog.openDialog(id);
        },
        transmitirRemessa(id){
            this.transmitindo = true;
            this.$apollo.mutate({
                mutation: gql`mutation ($id: Int!){
                        transmitirRemessa(id: $id)
                    }`,
                variables:{
                    id
                }
            }).finally(()=>{
                this.getRemessa();
                this.transmitindo = false;
            });
        },
        downloadRemessa(){
            this.downloadingDanfe = true;
            this.$axios.get('/cobrancas/' + this.remessa.id + '/download_remessa', {responseType: 'blob'}).then((response) => {
                const fileLink = document.createElement('a');
                fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                const fileName = response.headers['content-disposition'].split('filename=')[1];
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                this.downloadingDanfe = false;
            }).catch(() => {
                this.downloadingDanfe = false;
                this.$dialog.error({
                    text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                    title: 'Ops',
                });

            });
        }
    }
}
</script>

<style scoped>
.label{
    color: #616161 !important;
    caret-color: #616161 !important;

    font-size: 0.75rem !important;
    font-weight: 400;
    letter-spacing: 0.0333333333em !important;
    line-height: 1.25rem;

    display: block !important;
}
</style>