import { render, staticRenderFns } from "./TituloDialog.vue?vue&type=template&id=bcf924d8&scoped=true&"
import script from "./TituloDialog.vue?vue&type=script&lang=js&"
export * from "./TituloDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcf924d8",
  null
  
)

export default component.exports